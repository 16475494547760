import { IBaseEnvironment, IEnvironment } from '../app/types/environment';

const backEndUrl = 'https://be-demoads.arturhaunt.com';

export const baseEnvironment: IBaseEnvironment = {
  accessManagerUrl: 'accessManagerUrl',
  baseDomain: 'arturhaunt.com',
  backEndUrl,
};

export const environment: IEnvironment = {
  ...baseEnvironment,
  apiUrl: `${baseEnvironment.backEndUrl}/`,
  recaptchaKey: '6Ldc1bcUAAAAANC5QFxbf8WbQmXhF5-L7U_XMKu3',
  authClientId: 'eWW_Xxbn0NYk1mHXqYLd2DExOcvudYJCZfOmiLX86sY',
  authRedirectUri: 'com.arturin.arturapp://login',
};
